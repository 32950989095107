.switchViewCls {
    .ant-select-selection-item  {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
    .ant-select-selection-item > svg {
        display: none;
    }

    .ant-select-selector {
        height: 50px !important;
        border: 1px solid #d9d9d9 !important;
        box-shadow: none !important;
        display: flex;
        border-radius: 8px !important;
    }
}

.ant-select-item-option-content {
    display: flex;
}